import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { RefreshCw } from "lucide-react";
import { toast } from "sonner";
import ADUsersFilters from "src/components/ad-users/ad-users-filters";
import CompanySelect from "src/components/companys/company-select";
import { Button } from "src/components/ui/button";
import { UserADInterface } from "src/data/users";
import { appRoles, protectedResources } from "../authConfig";
import { ADUsersTable } from "../components/ad-users/ad-users-table";
import { CompanyInterface } from "../data/companies";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
const ADUserContent = () => {
  const { error, execute, account } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const [company, setCompany] = useState<null | string>(null);
  const [adUsers, setADUsers] = useState<null | UserADInterface[]>(null);
  const [companies, setCompanies] = useState<null | CompanyInterface[]>(null);

  const [filtredItems, setFiltredItems] = useState<UserADInterface[]>([]);

  const [loading, setLoading] = useState(false);

  const [loadingDisable, setLoadingDisable] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);

  useEffect(() => {
    if (company && !adUsers) {
      handleGetADUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, adUsers, company]);

  const handleDisable = async (user: string) => {
    setLoadingDisable(true);
    await execute(
      "DELETE",
      protectedResources.users.disableAdUsersEndpoint,
      undefined,
      {
        user,
      },
    )
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetADUsers();
        toast.success("Usuário desabilitado com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao desativar usuário");
      })
      .finally(() => {
        setLoadingDisable(false);
      });
  };

  const handleResetPassword = async (
    email: string,
    name: string,
    user: string,
  ) => {
    setLoadingReset(true);
    await execute(
      "PATCH",
      protectedResources.users.resetAdUsersPassword,
      {
        username: user,
        email,
        name,
      },
      {
        username: user,
        company,
      },
    )
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetADUsers();
        toast.success("Senha resetada com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao resetar senha do usuário");
      })
      .finally(() => {
        setLoadingReset(false);
      });
  };

  const handleGetADUsers = async () => {
    setLoading(true);
    execute("GET", protectedResources.users.adUsersListEndpoint, undefined, {
      company,
    })
      .then((response) => {
        setADUsers(response);
        setFiltredItems(response);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao buscar usuários");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterByName = (name: string) => {
    if (name.length === 0 && filtredItems.length > 0 && adUsers) {
      setFiltredItems(adUsers);
    } else if (adUsers) {
      setFiltredItems(
        adUsers.filter((item) => {
          return item.givenName.toLowerCase().indexOf(name.toLowerCase()) > -1;
        }),
      );
    }
  };

  useEffect(() => {
    if (company) handleGetADUsers();
  }, [company]);

  return (
    <div className="px-4">
      <div className="flex justify-between space-x-2">
        <h1 className="font-thin text-lg">
          {(adUsers && adUsers?.length) || 0} usuário(s) cadastrados
        </h1>
        <div className="w-2/6 flex space-x-1">
          <CompanySelect onSelectChange={(value) => setCompany(value)} />
          <Button
            disabled={loading || !company}
            onClick={() => handleGetADUsers()}
          >
            <RefreshCw className={`size-4 ${loading && "animate-spin"}`} />
          </Button>
        </div>
      </div>
      <ADUsersFilters handleFilter={handleFilterByName} />
      {adUsers ? (
        <ADUsersTable
          hasDisable={
            account?.idTokenClaims?.roles?.includes(
              appRoles.SysAdministrator,
            ) || account?.idTokenClaims?.roles?.includes(appRoles.DisableADUser)
              ? true
              : false
          }
          values={filtredItems}
          handleDisableADUser={handleDisable}
          handleResetADUserPassword={handleResetPassword}
          loadingDisable={loadingDisable}
          loadingReset={loadingReset}
        />
      ) : null}
    </div>
  );
};

export const ADUsersPage = () => {
  return <ADUserContent />;
};
