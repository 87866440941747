import CompanyTable from "../components/companys/company-table";
import { useCallback, useEffect, useState } from "react";

import { protectedResources } from "../authConfig";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { CompanyInterface } from "../data/companies";
import { CreateCompanyDialog } from "../components/companys/company-create-dialog";
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import CompanyFilters from "src/components/companys/company-filters";

export default function Companys() {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const [companies, setCompanies] = useState<CompanyInterface[] | null>(null);

  const [filtredItems, setFiltredItems] = useState<CompanyInterface[]>([]);

  const handleGetCompanies = async () => {
    const response = await execute(
      "GET",
      `${protectedResources.company.getCompaniesEndpoint}`,
    );
    setCompanies(response);
    setFiltredItems(response);
  };

  useEffect(() => {
    (async () => {
      if (!companies && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: protectedResources.users.scopes,
        };
        try {
          const accessToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          localStorage.setItem("backendAccessToken", accessToken.accessToken);
          await handleGetCompanies();
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessToken =
                await instance.acquireTokenSilent(accessTokenRequest);
              localStorage.setItem(
                "backendAccessToken",
                accessToken.accessToken,
              );
              await handleGetCompanies();
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    })();
  }, [instance, accounts, inProgress, companies]);

  const handleFilterByName = (name: string) => {
    if (name.length === 0 && filtredItems.length > 0 && companies) {
      setFiltredItems(companies);
    } else if (companies) {
      setFiltredItems(
        companies.filter((item) => {
          return item.name.toLowerCase().indexOf(name.toLowerCase()) > -1;
        }),
      );
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-start mb-4">
        <CompanyFilters handleFilter={handleFilterByName} />
        <CreateCompanyDialog />
      </div>

      {companies && <CompanyTable values={filtredItems} hasEdit />}
    </div>
  );
}
