import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { ChangesInterface } from "src/data/changes";
import { Button } from "../ui/button";
import ClientsChangesDialog from "./clients-changes-dialog";

export function ClientsChangesTable({
  values,
  loadingDisable,
}: {
  values: ChangesInterface[];
  loadingDisable: boolean;
  loadingReset: boolean;
}) {
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [openDisable, setOpenDisable] = useState<{
    gmudId: string;
    gmudType: string;
  } | null>(null);

  console.log(values);

  return (
    <>
      {openDisable && (
        <ClientsChangesDialog
          onClose={() => setOpenDisable(null)}
          gmudId={openDisable.gmudId}
          gmudType={openDisable.gmudType}
          loading={loadingDisable}
        />
      )}
      <Table id="table-ads">
        {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>Título</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Criticidade</TableHead>
            <TableHead>Impacto</TableHead>
            <TableHead>Tipo</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {values
            .sort((a, b) =>
              order === "asc"
                ? moment([
                    ...a.dt_criacao
                      .slice(0, 6)
                      .map((v, i) => (i === 1 ? v - 1 : v)),
                    a.dt_criacao[6] / 1e6,
                  ]).unix() -
                  moment([
                    ...b.dt_criacao
                      .slice(0, 6)
                      .map((v, i) => (i === 1 ? v - 1 : v)),
                    b.dt_criacao[6] / 1e6,
                  ]).unix()
                : moment([
                    ...b.dt_criacao
                      .slice(0, 6)
                      .map((v, i) => (i === 1 ? v - 1 : v)),
                    b.dt_criacao[6] / 1e6,
                  ]).unix() -
                  moment([
                    ...a.dt_criacao
                      .slice(0, 6)
                      .map((v, i) => (i === 1 ? v - 1 : v)),
                    a.dt_criacao[6] / 1e6,
                  ]).unix(),
            )
            .map((task) => (
              <TableRow key={task.id_mudanca}>
                <TableCell>
                  <Button
                    onClick={() =>
                      setOpenDisable({
                        gmudId: String(task.id_mudanca),
                        gmudType: task.tipoMudanca.tipo_mudanca,
                      })
                    }
                  >
                    Vizualizar
                  </Button>
                </TableCell>
                <TableCell className="font-medium">
                  {task.titulo_mudanca}
                </TableCell>
                <TableCell>{task.statusMudanca}</TableCell>
                <TableCell>{task.criticidade.criticidade}</TableCell>
                <TableCell>{task.impactoMudanca.impacto_mudanca}</TableCell>
                <TableCell>{task.tipoMudanca.tipo_mudanca}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
