import moment from "moment";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Textarea } from "../ui/textarea";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

import { useEffect, useState } from "react";
import { toast } from "sonner";
import { GMUDInformationInterface } from "src/data/approval";

const formatArrayDate = (date: number[]) => {
  return moment(
    new Date(date[0], date[1] - 1, date[2], date[3], date[4]),
  ).format("DD/MM/YYYY HH:mm");
};

export default function ClientsApprovalDialog({
  gmudId,
  gmudType,
  gmudApproverId,
  loading,
  onClose,
}: {
  gmudId: string;
  gmudType: string;
  gmudApproverId: number;
  loading: boolean;
  onClose: () => void;
}) {
  const [loaded, setLoaded] = useState(false);
  const [information, setInformation] =
    useState<GMUDInformationInterface | null>(null);

  const [changedStatusLoading, setChangedStatusLoading] = useState(false);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const handleGetGMUD = async (id: string, type: string) => {
    const response = await execute(
      "POST",
      `${protectedResources.approvals.approvalListByIdEndpoint}`,
      {
        id,
        type,
      },
    );

    setLoaded(true);
    if (response) {
      setInformation(response);
    }

    console.log(response);
  };

  const handleRejectGMUD = async (
    id: number,
    approverId: number,
    reason: string,
  ) => {
    try {
      setChangedStatusLoading(true);
      const response = await execute(
        "POST",
        protectedResources.approvals.gmudReproveEndpoint,
        { type: "reject", reason, id_gmud: id, id_approver: approverId },
      );
      console.log(response);

      toast.success("GMUD reprovada com sucesso!");

      onClose();
    } catch (err: any) {
      console.log("error changing status");
      console.log(err);
      toast.error("Erro ao realizar alteração!", {
        description: "Chame um administrador do sistema.",
      });
    } finally {
      setChangedStatusLoading(false);
    }
  };

  const handleApproveGMUD = async (id: number, approverId: number) => {
    try {
      setChangedStatusLoading(true);
      const response = await execute(
        "POST",
        protectedResources.approvals.gmudReproveEndpoint,
        { type: "approve", id_gmud: id, id_approver: approverId },
      );
      console.log(response);

      toast.success("GMUD aprovada com sucesso!");

      onClose();
    } catch (err: any) {
      console.log("error changing status");
      console.log(err);
      toast.error("Erro ao realizar alteração!", {
        description: "Chame um administrador do sistema.",
      });
    } finally {
      setChangedStatusLoading(false);
    }
  };

  useEffect(() => {
    console.log("into useEffect approvals list");
    console.log(
      gmudId,
      gmudType,
      !loaded,
      inProgress === InteractionStatus.None,
    );
    (async () => {
      if (
        gmudId &&
        gmudType &&
        !loaded &&
        inProgress === InteractionStatus.None
      ) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: protectedResources.users.scopes,
        };
        try {
          const accessToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          localStorage.setItem("backendAccessToken", accessToken.accessToken);
          await handleGetGMUD(gmudId, gmudType);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessToken =
                await instance.acquireTokenSilent(accessTokenRequest);
              localStorage.setItem(
                "backendAccessToken",
                accessToken.accessToken,
              );
              await handleGetGMUD(gmudId, gmudType);
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    })();
  }, [instance, accounts, inProgress, gmudId, gmudType, loaded]);

  return (
    <Dialog
      onOpenChange={(value) => {
        if (!value) onClose();
      }}
      open={true}
    >
      <DialogContent className="max-w-min max-h-full overflow-scroll">
        <DialogHeader>
          <DialogTitle>
            {information?.titulo_mudanca || "Carregando..."}
          </DialogTitle>
        </DialogHeader>
        {!information ? (
          <div className="flex justify-center items-center h-96">
            <h1 className="text-primary">Carregando...</h1>
          </div>
        ) : (
          <>
            <div className="divide-y space-y-2 divide-primary w-full h-full">
              <div className="flex space-x-2 py-2">
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Criticidade</h2>
                  <Input
                    value={information.criticidade.criticidade}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Impacto</h2>
                  <Input
                    value={information.impactoMudanca.impacto_mudanca}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Tipo</h2>
                  <Input
                    value={information.tipoMudanca.tipo_mudanca}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
              </div>
              <div className="flex space-x-2 pt-4">
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">
                    Data de execução
                  </h2>
                  <Input
                    value={formatArrayDate(information.dt_criacao)}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Data de inicio</h2>
                  <Input
                    value={formatArrayDate([
                      ...information.dadosMudanca.dt_inicio,
                      ...information.dadosMudanca.hr_inicio,
                    ])}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">
                    Data prevista de conclusão
                  </h2>
                  <Input
                    value={formatArrayDate([
                      ...information.dadosMudanca.dt_final,
                      ...information.dadosMudanca.hr_final,
                    ])}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
              </div>
              <div className="flex flex-col py-2 w-full">
                <h2 className="mb-1 text-primary text-sm">
                  Descrição da mudança
                </h2>
                <Textarea
                  value={information.dadosMudanca.dsc_mudanca}
                  disabled
                  rows={3}
                  readOnly
                  className="disabled:opacity-100"
                />
              </div>
              <div className="flex flex-col py-2 w-full">
                <h2 className="mb-1 text-primary text-sm">
                  Justificativa da mudança
                </h2>
                <Textarea
                  value={information.dadosMudanca.justificativa_mudanca}
                  disabled
                  rows={3}
                  readOnly
                  className="disabled:opacity-100"
                />
              </div>
              <div className="flex flex-col py-2 w-full">
                <h2 className="mb-1 text-primary text-sm">Plano de teste</h2>
                <Textarea
                  value={information.acaoPosAtividade.plano_teste || ""}
                  disabled
                  rows={3}
                  readOnly
                  className="disabled:opacity-100"
                />
              </div>
              <div className="flex flex-col py-2 w-full">
                <h2 className="text-primary text-sm">Plano de retorno</h2>
                <Textarea
                  value={information.acaoPosAtividade.plano_rollback || ""}
                  disabled
                  rows={3}
                  readOnly
                  className="disabled:opacity-100"
                />
              </div>
              {/* <div className="flex flex-col py-2 w-full">
            <h2 className="text-primary text-sm">
              Clientes afetados pela mudança
            </h2>
            <div className="h-36 overflow-y-auto w-full">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>ID</TableHead>
                    <TableHead>Cliente</TableHead>
                    <TableHead>ALIAS</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {information.mudancaClientesAfetados.map((cliente) => (
                    <TableRow key={cliente.clientesAfetados.id_cliente_portal}>
                      <TableCell>
                        {cliente.clientesAfetados.id_cliente_portal}
                      </TableCell>
                      <TableCell>
                        {cliente.clientesAfetados.nome_cliente}
                      </TableCell>
                      <TableCell>{cliente.clientesAfetados.alias}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div> */}
              <div className="flex flex-col py-2 w-full">
                <h2 className="text-primary text-sm">Tarefas</h2>
                <div className="h-36 overflow-y-auto w-full">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Nome</TableHead>
                        <TableHead>Responsável</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Data de criação</TableHead>
                        <TableHead>Data de inicio</TableHead>
                        <TableHead>Data de conclusão</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {information.atividadesMudanca.map(
                        (atividadesMudanca) => (
                          <TableRow
                            key={atividadesMudanca.id_atividade_mudanca}
                          >
                            <TableCell>
                              {atividadesMudanca.titulo_atividade_mudanca}
                            </TableCell>
                            <TableCell>
                              {
                                atividadesMudanca.responsavelAtividade
                                  .login_responsavel_atividade
                              }
                            </TableCell>
                            <TableCell>
                              {
                                atividadesMudanca.statusAtividade
                                  .status_atividade
                              }
                            </TableCell>
                            <TableCell>
                              {atividadesMudanca.statusAtividade.dt_criacao &&
                                formatArrayDate(
                                  atividadesMudanca.statusAtividade.dt_criacao,
                                )}
                            </TableCell>
                            <TableCell>
                              {atividadesMudanca.dt_inicio_tarefa &&
                                formatArrayDate(
                                  atividadesMudanca.dt_inicio_tarefa,
                                )}
                            </TableCell>
                            <TableCell>
                              {atividadesMudanca.dt_final_tarefa &&
                                formatArrayDate(
                                  atividadesMudanca.dt_final_tarefa,
                                )}
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <DialogFooter>
              <Button
                onClick={() =>
                  handleApproveGMUD(information.id_mudanca, gmudApproverId)
                }
                disabled={loading || changedStatusLoading}
              >
                Aprovar
              </Button>
              <Dialog>
                <DialogTrigger>
                  <Button
                    disabled={loading || changedStatusLoading}
                    // onClick={handleDisable}
                    variant={"destructive"}
                  >
                    Rejeitar
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <h2 className="my-1 text-primary text-sm">Justificativa</h2>
                  <Textarea
                    id="reject_reason"
                    rows={3}
                    className="disabled:opacity-100"
                  />
                  <div className="flex justify-end">
                    <Button
                      disabled={loading}
                      // onClick={handleDisable}
                      onClick={() =>
                        handleRejectGMUD(
                          information.id_mudanca,
                          gmudApproverId,
                          (
                            document.getElementById(
                              "reject_reason",
                            ) as HTMLTextAreaElement
                          )?.value,
                        )
                      }
                      variant={"destructive"}
                    >
                      Rejeitar
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
