import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { CompanyInterface } from "../../data/companies";
import { Button } from "../ui/button";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { DeleteCompanyDialog } from "./company-delete-dialog";
import { CompanyForm } from "./company-form";

export default function CompanyTable({
  values,
  hasEdit,
}: {
  values: CompanyInterface[];
  hasEdit: boolean;
}) {
  return (
    <Table>
      {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
      <TableHeader>
        <TableRow>
          <TableHead className="font-medium">ID</TableHead>
          <TableHead>Nome</TableHead>
          <TableHead>Ações</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {values
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((company: CompanyInterface) => (
            <TableRow key={company.name}>
              <TableCell className="font-medium">{company.name}</TableCell>
              <TableCell>{company.name}</TableCell>
              {hasEdit && (
                <TableCell className="space-x-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button className="space-x-2">Editar</Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[720px]">
                      <DialogHeader>
                        <DialogTitle>Editar empresa</DialogTitle>
                      </DialogHeader>
                      <CompanyForm companyToEdit={company.id} />
                    </DialogContent>
                  </Dialog>
                  <DeleteCompanyDialog name={company.name} id={company.id} />
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
