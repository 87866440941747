import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { toast } from "sonner";
import { ClientsApprovalTable } from "src/components/clients-approval/clients-approval-table";
import { ApprovalInterface } from "src/data/approval";
import { UserADInterface } from "src/data/users";
import { protectedResources } from "../authConfig";
import { CompanyInterface } from "../data/companies";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

const ClientsApprovalContent = () => {
  const { error, execute, account } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const [company, setCompany] = useState<null | string>(null);
  const [approvals, setApprovals] = useState<null | ApprovalInterface[]>(null);
  const [companies, setCompanies] = useState<null | CompanyInterface[]>(null);

  const [filtredItems, setFiltredItems] = useState<UserADInterface[]>([]);

  const [loading, setLoading] = useState(false);

  const [loadingDisable, setLoadingDisable] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);

  useEffect(() => {
    if (company && !approvals) {
      handleGetApprovalList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, approvals, company]);

  const handleDisable = async (user: string) => {
    setLoadingDisable(true);
    await execute(
      "DELETE",
      protectedResources.users.disableAdUsersEndpoint,
      undefined,
      {
        user,
      },
    )
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetApprovalList();
        toast.success("Usuário desabilitado com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao desativar usuário");
      })
      .finally(() => {
        setLoadingDisable(false);
      });
  };

  const handleReject = async (
    reason: string,
    approvalId: string,
    gmudId: string,
  ) => {
    setLoadingReset(true);
    await execute("POST", protectedResources.approvals.gmudReproveEndpoint, {
      reason,
      id_approver: approvalId,
      id_gmud: gmudId,
    })
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetApprovalList();
        toast.success("Senha resetada com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao resetar senha do usuário");
      })
      .finally(() => {
        setLoadingReset(false);
      });
  };

  const handleGetApprovalList = async () => {
    console.log("handleGetApprovalList");
    setLoading(true);
    execute(
      "GET",
      protectedResources.approvals.approvalsListEndpoint,
      undefined,
      {
        company,
      },
    )
      .then((response) => {
        setApprovals(response);
        setFiltredItems(response);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao buscar usuários");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleGetApprovalList();
  }, []);

  return (
    <div className="px-4">
      <ClientsApprovalTable
        values={approvals || []}
        loadingDisable={loadingDisable}
        loadingReset={loadingReset}
        getGmuds={handleGetApprovalList}
      />
    </div>
  );
};

export const ClientsApprovalPage = () => {
  return <ClientsApprovalContent />;
};
