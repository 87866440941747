import { GripHorizontal } from "lucide-react";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

export default function ADUsersDropdownActions({
  onDisable,
  onReset,
}: {
  onDisable: () => void;
  onReset: () => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button>
          <GripHorizontal size={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={onDisable}
          className="text-red-600 cursor-pointer flex justify-between space-x-2"
        >
          {/* <UserMinus size={16} /> */}
          Desativar
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={onReset}
          className="text-red-600 cursor-pointer flex justify-between space-x-2"
        >
          Resetar senha
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
