import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { appRoles, loginRequest } from "../../authConfig";

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RouteGuard = ({ ...props }) => {
  const location = useLocation();
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const currentAccount = instance.getActiveAccount();

  const authRequest = {
    ...loginRequest,
  };

  const onLoad = async () => {
    if (currentAccount && currentAccount.idTokenClaims?.roles) {
      let intersection = props.roles.filter((role: any) =>
        currentAccount.idTokenClaims?.roles?.includes(role),
      );

      if (intersection.length > 0 || props.roles.length === 0) {
        setIsAuthorized(true);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance, currentAccount]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {isAuthorized ? (
        <div>{props.children}</div>
      ) : (
        <div className="data-area-div">
          <h3>You are unauthorized to view this content.</h3>
        </div>
      )}
    </MsalAuthenticationTemplate>
  );
};
