import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button, buttonVariants } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

import { protectedPages } from "../../authConfig";
import { ArrowLeftIcon, SkipBack } from "lucide-react";
import { cn } from "src/lib/utils";

const getInitials = function (string: string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const NavigationBar = () => {
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <>
      <nav className="w-full flex justify-between bg-primary p-2 pl-8 border-b items-center h-[8vh]">
        <a className="navbar-brand" href="/">
          <img
            src="/seidor-multicloud.png"
            alt="Logo seidor small"
            className="h-8 w-auto"
          />
        </a>
        <p className="text-white">
          {(protectedPages as any)[
            Object.keys(protectedPages).find((v) =>
              window.location.pathname.includes(
                (protectedPages as any)[v].path,
              ),
            ) || "dashboard"
          ]?.label || ""}
        </p>
        <AuthenticatedTemplate>
          <div className="">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size="icon"
                  variant={"ghost"}
                  className="flex justify-center h-12 items-center space-x-2 w-full p-2 bg-white rounded-lg shadow-lg"
                >
                  <div className="rounded-full w-10 h-10 flex justify-center items-center bg-primary text-white">
                    {getInitials(activeAccount?.name || "")}
                  </div>
                  <p className="text-primary text-lg font-semi">
                    {activeAccount?.name}
                  </p>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Minha conta</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {/* <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Support</DropdownMenuItem> */}
                <DropdownMenuItem onClick={handleLogoutRedirect}>
                  Sair
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {window.location.pathname.includes("dashboard") && (
            <Button
              onClick={handleLoginRedirect}
              className="bg-white text-primary"
            >
              Fazer login
            </Button>
          )}
        </UnauthenticatedTemplate>
      </nav>
      {Object.keys(protectedPages).find((v) =>
        window.location.pathname.includes((protectedPages as any)[v].path),
      ) &&
        !window.location.pathname.includes("dashboard") && (
          <AuthenticatedTemplate>
            <div className="my-4 ml-4">
              <a
                href="/"
                className={cn(
                  `space-x-2 flex items-center`,
                  buttonVariants({ variant: "default" }),
                )}
              >
                <ArrowLeftIcon size={18} />
                <p>Voltar</p>
              </a>
            </div>
          </AuthenticatedTemplate>
        )}
    </>
  );
};
