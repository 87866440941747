import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { ApprovalInterface } from "src/data/approval";
import { Button } from "../ui/button";
import ClientsApprovalDialog from "./clients-approval-dialog";

export function ClientsApprovalTable({
  values,
  loadingDisable,
  getGmuds,
}: {
  values: ApprovalInterface[];
  loadingDisable: boolean;
  loadingReset: boolean;
  getGmuds: () => Promise<void>;
}) {
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [openDisable, setOpenDisable] = useState<{
    gmudId: string;
    gmudType: string;
    gmudApproverId: number;
  } | null>(null);

  console.log(values);

  return (
    <>
      {openDisable && (
        <ClientsApprovalDialog
          onClose={() => {
            setOpenDisable(null);
            getGmuds();
          }}
          gmudId={openDisable.gmudId}
          gmudType={openDisable.gmudType}
          gmudApproverId={openDisable.gmudApproverId}
          loading={loadingDisable}
        />
      )}
      <Table id="table-ads">
        {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>Título</TableHead>
            <TableHead>Criticidade</TableHead>
            <TableHead>Impacto</TableHead>
            <TableHead>Tipo</TableHead>
          </TableRow>
        </TableHeader>
        {values.length <= 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={5} className="text-center">
                Nenhuma mudança encontrada
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {values
              .sort((a, b) =>
                order === "asc"
                  ? moment([
                      ...a.dt_criacao_aprovacao
                        .slice(0, 6)
                        .map((v, i) => (i === 1 ? v - 1 : v)),
                      a.dt_criacao_aprovacao[6] / 1e6,
                    ]).unix() -
                    moment([
                      ...b.dt_criacao_aprovacao
                        .slice(0, 6)
                        .map((v, i) => (i === 1 ? v - 1 : v)),
                      b.dt_criacao_aprovacao[6] / 1e6,
                    ]).unix()
                  : moment([
                      ...b.dt_criacao_aprovacao
                        .slice(0, 6)
                        .map((v, i) => (i === 1 ? v - 1 : v)),
                      b.dt_criacao_aprovacao[6] / 1e6,
                    ]).unix() -
                    moment([
                      ...a.dt_criacao_aprovacao
                        .slice(0, 6)
                        .map((v, i) => (i === 1 ? v - 1 : v)),
                      a.dt_criacao_aprovacao[6] / 1e6,
                    ]).unix(),
              )
              .map((task) => (
                <TableRow key={task.id_mudanca}>
                  <TableCell>
                    <Button
                      onClick={() =>
                        setOpenDisable({
                          gmudId: String(task.id_mudanca),
                          gmudType: task.tipo_mudanca,
                          gmudApproverId: task.id_lista_aprovadores,
                        })
                      }
                    >
                      Vizualizar
                    </Button>
                  </TableCell>
                  <TableCell className="font-medium">
                    {task.titulo_mudanca}
                  </TableCell>
                  <TableCell>{task.criticidade}</TableCell>
                  <TableCell>{task.impacto_mudanca}</TableCell>
                  <TableCell>{task.tipo_mudanca}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
    </>
  );
}
