import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { InfoIcon } from "lucide-react";

interface CounterInformation {
  label: string;
  count: number;
}

interface ActiveUsersCounterProps {
  counters: CounterInformation[];
}

export default function ActiveUsersCounter({
  counters,
}: ActiveUsersCounterProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="secondary">
          <div className="flex space-x-2 items-center text-lg">
            <h1 className="font-thin">
              {counters.reduce((acc, curr) => {
                return acc + curr.count;
              }, 0)}{" "}
              usuário(s) ativos
            </h1>
            <InfoIcon />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="divide-y-2 ">
        {counters.map((counter) => (
          <div key={counter.label} className="flex justify-between p-2">
            <p>{counter.label.split("@")[0]}</p>
            <p>{counter.count}</p>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
}
