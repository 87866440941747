import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { protectedPages } from "../authConfig";

function Card({
  label,
  icon,
  href,
}: {
  label: string;
  icon: JSX.Element;
  href: string;
}) {
  return (
    <a
      href={href}
      className="border-b-2 hover:border-primary bg-gray-100 hover:bg-primary/10 p-8 rounded-lg hover:border-b-2 transition-all delay-100 cursor-pointer text-gray-600 hover:text-black flex flex-col items-center space-y-2 w-64"
    >
      {icon}
      <p className="text-lg font-medium text-center">{label}</p>
    </a>
  );
}

export default function Dashboard() {
  const { instance, inProgress, accounts } = useMsal();

  const activeAccount = instance.getActiveAccount();

  const roles = activeAccount?.idTokenClaims?.roles;

  return (
    <AuthenticatedTemplate>
      <div className="p-8">
        <h1 className="text-bold text-2xl text-slate-400">Gestão de acesso</h1>
        {protectedPages.listActiveUsersPage.roles.find((v) =>
          roles?.includes(v || ""),
        ) && (
          <div className="mt-4 border-b-2 pb-4 border-newgray">
            <div className="flex space-x-2">
              <Card
                label={protectedPages.listActiveUsersPage.label}
                href={protectedPages.listActiveUsersPage.path}
                icon={
                  <img
                    src="/moduleone.png"
                    className="size-20"
                    alt="Remote App"
                  />
                }
              />
              <Card
                label={protectedPages.listADUsersPage.label}
                href={protectedPages.listADUsersPage.path}
                icon={
                  <img
                    src="/moduletwo.png"
                    className="size-20"
                    alt="Usuários ativos"
                  />
                }
              />
            </div>
          </div>
        )}

        <h1 className="mt-8 text-bold text-2xl text-slate-400">
          Gestão de mudanças
        </h1>
        {protectedPages.approvalClientsPage.roles.find((v) =>
          roles?.includes(v || ""),
        ) && (
          <div className="mt-4 border-b-2 pb-4 border-newgray">
            <div className="flex space-x-2">
              <Card
                label={protectedPages.changesClientsPage.label}
                href={protectedPages.changesClientsPage.path}
                icon={
                  <img
                    src="/change-management.png"
                    className="size-20"
                    alt="GMUD"
                  />
                }
              />
              <Card
                label={protectedPages.approvalClientsPage.label}
                href={protectedPages.approvalClientsPage.path}
                icon={
                  <img src="/aprovado.png" className="size-20" alt="GMUD" />
                }
              />
            </div>
          </div>
        )}

        {protectedPages.listCompaniesPage.roles.find((v) =>
          roles?.includes(v || ""),
        ) && (
          <div className="mt-8 pb-4 border-b-2 border-newgray">
            <h1 className="text-bold text-2xl text-slate-400 mb-4">
              Configurações
            </h1>
            <div className="flex space-x-2">
              <Card
                label={protectedPages.listCompaniesPage.label}
                href={protectedPages.listCompaniesPage.path}
                icon={
                  <img
                    src="/moduletree.png"
                    className="size-20"
                    alt="Empresas"
                  />
                }
              />
              <Card
                label={protectedPages.listLogsPage.label}
                href={protectedPages.listLogsPage.path}
                icon={
                  <img
                    src="/moduletree.png"
                    className="size-20"
                    alt="Empresas"
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
    </AuthenticatedTemplate>
  );
}
