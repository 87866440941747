import { XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

export default function ADUsersFilters({
  handleFilter,
}: {
  handleFilter: (name: string) => void;
}) {
  const [name, setName] = useState("");

  useEffect(() => {
    handleFilter(name);
  }, [name]);

  return (
    <div className="w-2/6 flex flex-col mb-2">
      <h2 className="font-semibold mb-1">Filtros</h2>
      <div className="w-full flex relative">
        <Input
          className="pr-16"
          value={name}
          placeholder="Insira um nome..."
          onChange={(e) => setName(e.target.value)}
        />
        {name.length > 0 && (
          <Button onClick={() => setName("")} className="absolute right-0">
            <XIcon />
          </Button>
        )}
      </div>
    </div>
  );
}
