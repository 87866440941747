import { useEffect, useState } from "react";
import Select from "react-select";

import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { toast } from "sonner";
import { CompanyInterface } from "../../data/companies";

type OptionType = {
  value: string;
  label: string;
};

export default function CompanySelect({
  onSelectChange,
}: {
  onSelectChange: (company: string) => void;
}) {
  const { error, execute, account } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();
  const [company, setCompany] = useState<OptionType | undefined>();
  const [companies, setCompanies] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(true);

  const handleGetComapnies = async () => {
    try {
      setLoading(true);
      const response = await execute(
        "GET",
        protectedResources.company.getCompaniesEndpoint,
      );
      console.log(response);
      setCompanies(
        response.map((v: CompanyInterface) => ({ value: v.id, label: v.name })),
      );
    } catch (error: any) {
      console.log(error);
      toast.error("Erro ao buscar empresas");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("inside useEffect");
    (async () => {
      if (companies.length <= 0 && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: protectedResources.users.scopes,
        };
        try {
          const accessToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          localStorage.setItem("backendAccessToken", accessToken.accessToken);
          await handleGetComapnies();
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessToken =
                await instance.acquireTokenSilent(accessTokenRequest);
              localStorage.setItem(
                "backendAccessToken",
                accessToken.accessToken,
              );
              await handleGetComapnies();
            } catch (error) {
              console.log(error);
              toast.error("Erro ao buscar empresas");
            }
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts, inProgress, companies]);

  return (
    <Select
      className="w-full"
      options={companies.sort((a, b) => a.label.localeCompare(b.label))}
      onChange={(value) => {
        if (value) onSelectChange(value.value);
      }}
      placeholder="Selecione..."
      isLoading={loading}
      isSearchable
      isClearable
    />
  );
}
