import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { RefreshCw } from "lucide-react";
import { toast } from "sonner";
import ActiveUsersCounter from "src/components/active-users/active-users-counter";
import ActiveUsersFilters from "src/components/active-users/active-users-filters";
import CompanySelect from "src/components/companys/company-select";
import { Button } from "src/components/ui/button";
import { UserActiveInterface } from "src/data/users";
import { appRoles, protectedResources } from "../authConfig";
import { ActiveUsersTable } from "../components/active-users/active-users-table";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

const ActiveUsersContent = () => {
  const { error, execute, account } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const [company, setCompany] = useState<null | string>(null);
  const [activeUsers, setActiveUsers] = useState<null | UserActiveInterface[]>(
    null,
  );

  const [filtredItems, setFiltredItems] = useState<UserActiveInterface[]>([]);

  const [loading, setLoading] = useState(false);

  const [loadingDisconnect, setLoadingDisconnect] = useState(false);
  useEffect(() => {
    if (company && !activeUsers) {
      handleGetActiveUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, activeUsers, company]);

  const handleKick = async (id: string, host: string, username: string) => {
    setLoadingDisconnect(true);
    await execute(
      "DELETE",
      protectedResources.users.kickActiveUserEndpoint,
      undefined,
      {
        session_id: id,
        username,
        company,
        host_ip: host,
      },
    )
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetActiveUsers();
        toast.success("Usuário desconectado com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao desconectar usuário");
      })
      .finally(() => {
        setLoadingDisconnect(false);
      });
  };

  const handleGetActiveUsers = async () => {
    setLoading(true);
    await execute(
      "GET",
      protectedResources.users.activeUsersListEndpoint,
      undefined,
      {
        company,
      },
    )
      .then((response) => {
        setActiveUsers(response);
        setFiltredItems(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterByUserName = (name: string) => {
    if (name.length === 0 && filtredItems.length > 0 && activeUsers) {
      setFiltredItems(activeUsers);
    } else if (activeUsers) {
      setFiltredItems(
        activeUsers.filter((item) => {
          return item.username.toLowerCase().indexOf(name.toLowerCase()) > -1;
        }),
      );
    }
  };

  return (
    <div className="px-4">
      <div className="flex justify-end space-x-2 mb-4">
        <div className="w-2/6 flex space-x-1">
          <CompanySelect
            onSelectChange={(v) => {
              setActiveUsers(null);
              setCompany(v);
            }}
          />
          <Button
            disabled={loading || !company}
            onClick={() => handleGetActiveUsers()}
          >
            <RefreshCw className={`size-4 ${loading && "animate-spin"}`} />
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <ActiveUsersFilters handleFilter={handleFilterByUserName} />
        <ActiveUsersCounter
          counters={(() => {
            if (!activeUsers) return [];
            const obj = activeUsers?.reduce(
              (acc, user) => {
                if (acc[user.host]) {
                  acc[user.host] += 1;
                } else {
                  acc[user.host] = 1;
                }
                return acc;
              },
              {} as { [key: string]: number },
            );
            return Object.keys(obj).map((key) => ({
              label: key,
              count: obj[key],
            }));
          })()}
        />
      </div>

      {activeUsers ? (
        <ActiveUsersTable
          values={filtredItems}
          hasKick={
            account?.idTokenClaims?.roles?.includes(
              appRoles.SysAdministrator,
            ) ||
            account?.idTokenClaims?.roles?.includes(appRoles.KickActiveUser)
              ? true
              : false
          }
          handleKickActiveUser={handleKick}
          loadingDisconnect={loadingDisconnect}
        />
      ) : null}
    </div>
  );
};

export const ActiveUsersPage = () => {
  return <ActiveUsersContent />;
};
