import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

import useFetchWithMsal from "../../hooks/useFetchWithMsal";

import { useState } from "react";
import { toast } from "sonner";
import { protectedResources } from "src/authConfig";

export function DeleteCompanyDialog({
  name,
  id,
}: {
  name: string;
  id: string;
}) {
  const [loading, setLoading] = useState(false);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const handleDeleteCompany = async (id: string, companyName: string) => {
    try {
      setLoading(true);
      const response = await execute(
        "DELETE",
        protectedResources.company.deleteCompanyEndpoint + id,
        undefined,
        {
          username: id,
          company: id,
        },
      );
      console.log(response);
      toast.success("Empresa deletada com sucesso!");
    } catch (err: any) {
      console.log("error creating company");
      console.log(err);
      toast.error("Erro ao realizar alteração!", {
        description: "Chame um administrador do sistema.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"destructive"} className="space-x-2">
          <p>Deletar</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px]">
        <DialogHeader>
          <DialogTitle>Deletar empresa</DialogTitle>
        </DialogHeader>
        <p>
          Tem certeza que deseja deletar a empresa
          <strong> {name}</strong>?
        </p>
        <DialogFooter>
          <Button
            variant={"destructive"}
            disabled={loading}
            onClick={() => handleDeleteCompany(id, name)}
          >
            Deletar
          </Button>
          <DialogClose asChild>
            <Button variant={"secondary"}>Cancelar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
