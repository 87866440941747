import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

import { useFieldArray, useForm } from "react-hook-form";

import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyInterface } from "../../data/companies";
import { DialogClose, DialogFooter } from "../ui/dialog";

import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

import { toast } from "sonner";
import { useEffect, useState } from "react";

const companiesSchema = z.object({
  name: z
    .string({
      required_error: "É necessário inserir um nome para essa empresa.",
    })
    .min(1, "É necessário inserir um nome para essa empresa."),
  roleName: z
    .string({
      required_error: "É necessário inserir a 'role' da empresa.",
    })
    .min(1, "É necessário inserir a 'role' da empresa."),
  baseDN: z
    .string({
      required_error: "É necessário inserir a 'baseDN' da empresa.",
    })
    .min(1, "É necessário inserir a 'baseDN' da empresa."),
  ips: z.array(
    z.object({
      name: z.string({
        required_error: "É necessário um nome para o IP.",
      }),
      value: z.string({
        required_error: "É necessário um IP.",
      }),
    }),
  ),
});

export type CompanyCreateSchema = z.infer<typeof companiesSchema>;

interface CompanyFormProps {
  companyToEdit?: string;
}

interface CompanyToEditProps extends CompanyCreateSchema {
  id?: string;
}

export function CompanyForm({ companyToEdit }: CompanyFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CompanyCreateSchema>({
    resolver: zodResolver(companiesSchema),
  });

  const [loadedCompany, setLoadedCompany] = useState(false);

  const { fields, append, remove } = useFieldArray({
    name: "ips",
    control,
  });

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const handleGetCompanyById = async (id: string) => {
    const response = await execute(
      "GET",
      `${protectedResources.company.getCompanyByIdEndpoint}/${id}`,
    );

    setLoadedCompany(true);

    setValue("name", response.name);
    setValue("roleName", response.roleName.replace("companyRole.", ""));
    setValue("baseDN", response.baseDN);
    response.ips.forEach((ip: any) => {
      append(ip);
    });

    console.log(response);
  };

  const handleCreateOrUpdateCompany = async (company: CompanyToEditProps) => {
    try {
      const response = await execute(
        "PUT",
        protectedResources.company.createOrUpdateCompanyEndpoint,
        {
          ...company,
          roleName: `companyRole.${company.roleName}`,
        },
      );
      console.log(response);
    } catch (err: any) {
      console.log("error creating company");
      console.log(err);
      toast.error("Erro ao realizar alteração!", {
        description: "Chame um administrador do sistema.",
      });
    }
  };

  useEffect(() => {
    console.log("into useEffect");
    (async () => {
      if (
        companyToEdit &&
        !loadedCompany &&
        inProgress === InteractionStatus.None
      ) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: protectedResources.users.scopes,
        };
        try {
          const accessToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          localStorage.setItem("backendAccessToken", accessToken.accessToken);
          await handleGetCompanyById(companyToEdit);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessToken =
                await instance.acquireTokenSilent(accessTokenRequest);
              localStorage.setItem(
                "backendAccessToken",
                accessToken.accessToken,
              );
              await handleGetCompanyById(companyToEdit);
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    })();
  }, [instance, accounts, inProgress, companyToEdit, loadedCompany]);

  async function handleSubmitCompany(data: CompanyCreateSchema) {
    try {
      if (companyToEdit) {
        const response = await handleCreateOrUpdateCompany({
          ...data,
          id: companyToEdit,
        });
        console.log("response");
        console.log(response);

        toast.success("Empresa editada com sucesso!", {
          description: "Seu empresa foi editada com sucesso.",
        });
      } else {
        const response = await handleCreateOrUpdateCompany(data);
        console.log("response");
        console.log(response);

        toast.success("Empresa criada com sucesso!", {
          description: "Sua empresa foi criada com sucesso.",
        });

        reset({
          name: "",
          roleName: "",
          baseDN: "",
          ips: [],
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Erro ao realizar alteração!", {
        description: "Chame um administrador do sistema.",
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCompany)} className="grid gap-4">
      <div className="grid grid-cols-4 items-start gap-4">
        <Label htmlFor="title" className="text-right">
          Nome da empresa
        </Label>
        <div className="col-span-3">
          <Input
            id="title"
            error={errors.name?.message}
            disabled={isSubmitting || !!(companyToEdit && !loadedCompany)}
            {...register("name")}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 items-start gap-4">
        <Label htmlFor="coupon" className="text-right">
          Role (AZURE AD)
        </Label>
        <div className="col-span-3 relative">
          <Input
            id="coupon"
            error={errors.roleName?.message}
            className="pl-32"
            disabled={
              isSubmitting ||
              !!(companyToEdit && !loadedCompany) ||
              !!companyToEdit
            }
            {...register("roleName")}
          />
          <Input
            value="companyRole."
            readOnly
            disabled
            className="absolute left-0 top-0 bottom-0 w-28 text-xs text-gray-500"
          />
          <span className="text-sm text-red-300 mt-1">
            Este campo não pode ser editado posteriormente.
          </span>
        </div>
      </div>
      <div className="grid grid-cols-4 items-start gap-4">
        <Label htmlFor="baseDN" className="text-right">
          BaseDN
        </Label>
        <div className="col-span-3">
          <Input
            id="baseDN"
            error={errors.baseDN?.message}
            disabled={isSubmitting || !!(companyToEdit && !loadedCompany)}
            {...register("baseDN")}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 items-start gap-4">
        <Label htmlFor="coupon" className="text-right">
          HOSTs
        </Label>
        <div className="col-span-3">
          {fields.map((field, index) => {
            const errorForField = errors?.ips?.[index];
            return (
              <div className="flex items-center space-x-2" key={field.id}>
                <Input
                  {...register(`ips.${index}.name` as const)}
                  placeholder="Insira um nome"
                  defaultValue={field.name}
                  error={errorForField?.name?.message}
                />
                <Input
                  {...register(`ips.${index}.value` as const)}
                  placeholder="Insira um valor"
                  defaultValue={field.name}
                  error={errorForField?.value?.message}
                />
                <Button
                  type="button"
                  variant={"destructive"}
                  disabled={isSubmitting || !!(companyToEdit && !loadedCompany)}
                  onClick={() => remove(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </Button>
              </div>
            );
          })}
          <div className="flex justify-end mt-2">
            <Button
              type="button"
              disabled={isSubmitting || !!(companyToEdit && !loadedCompany)}
              onClick={() => {
                append({ name: "", value: "" });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant={"destructive"}>Fechar</Button>
        </DialogClose>
        <Button
          disabled={isSubmitting || !!(companyToEdit && !loadedCompany)}
          type="submit"
        >
          {companyToEdit ? "Salvar" : "Criar"}
        </Button>
      </DialogFooter>
    </form>
  );
}
