import { useState, useCallback, useRef, useEffect } from "react";

import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import axios from "axios";

const REFRESH_THRESHOLD = 300; // 5 minutes in seconds
const TOKEN_CHECK_INTERVAL = 60000; // 1 minute in milliseconds

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = (msalRequest) => {
  const { instance, inProgress } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const account = instance.getActiveAccount();

  const {
    result,
    error: msalError,
    acquireToken,
  } = useMsalAuthentication(InteractionType.Redirect, {
    ...msalRequest,
    account,
    redirectUri: "/",
  });

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @param {Object} headers: The data to send to the endpoint, if any
   * @returns JSON response
   */
  const execute = async (method, endpoint, data = null, headers = null) => {
    // if (msalError) {
    //   console.log("msalError");
    //   console.log(msalError);
    //   setError(msalError);
    //   return;
    // }

    try {
      let response = null;

      const backendAccessToken = localStorage.getItem("backendAccessToken");

      const bearer = `Bearer ${backendAccessToken}`;

      setIsLoading(true);

      const { data: responseFetch } = await axios(endpoint, {
        method,
        headers: {
          Authorization: bearer,
          ...headers,
        },
        data: data || null,
      });

      response = responseFetch;
      setData(response);

      setIsLoading(false);
      return response;
    } catch (e) {
      console.error("error fetching data");
      console.error(e.message);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    data,
    account,
    execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetchWithMsal;
