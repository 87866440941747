import { CompanyInterface } from "../../data/companies";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

import { CompanyForm } from "./company-form";

export function CreateCompanyDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>

          <p>Criar nova empresa</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px]">
        <DialogHeader>
          <DialogTitle>Crie um nova empresa</DialogTitle>
        </DialogHeader>
        <CompanyForm />
      </DialogContent>
    </Dialog>
  );
}
