import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

export default function ActiveUsersDisconnectDialog({
  handleDisconnect,
  name,
  loading,
  onClose,
}: {
  handleDisconnect: () => void;
  name: string;
  loading: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      onOpenChange={(value) => {
        if (!value) onClose();
      }}
      open={true}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Desconectar usuário</DialogTitle>
          <DialogDescription>
            Você tem certeza que deseja desconectar <strong>{name}</strong>?
            Esta ação não pode ser desfeita.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            disabled={loading}
            onClick={handleDisconnect}
            variant={"destructive"}
          >
            {loading ? "Desconectando..." : "Desconectar"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
