import { MsalProvider } from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";

import { PageLayout } from "./components/authentication/PageLayout";
import { RouteGuard } from "./components/authentication/RouteGuard";
import { Home } from "./pages/Home";

import { protectedPages } from "./authConfig";

import { NavigationBar } from "./components/authentication/navigation-bar";
import { ActiveUsersPage } from "./pages/active-users-page";
import { ADUsersPage } from "./pages/ad-users-page";
import { ClientsApprovalPage } from "./pages/clients-approval-page";
import { ClientsChangesPage } from "./pages/clients-changes-page";
import Companys from "./pages/companies-page";
import Logs from "./pages/logs-page";
import "./styles/App.css";

const Pages = () => {
  return (
    <Routes>
      <Route
        caseSensitive
        path={protectedPages.listActiveUsersPage.path}
        element={
          <RouteGuard roles={protectedPages.listActiveUsersPage.roles}>
            <NavigationBar />
            <ActiveUsersPage />
          </RouteGuard>
        }
      />
      <Route
        caseSensitive
        path={protectedPages.listADUsersPage.path}
        element={
          <RouteGuard roles={protectedPages.listADUsersPage.roles}>
            <NavigationBar />
            <ADUsersPage />
          </RouteGuard>
        }
      />
      <Route
        caseSensitive
        path={protectedPages.listCompaniesPage.path}
        element={
          <RouteGuard roles={protectedPages.listCompaniesPage.roles}>
            <NavigationBar />
            <Companys />
          </RouteGuard>
        }
      />
      <Route
        caseSensitive
        path={protectedPages.listLogsPage.path}
        element={
          <RouteGuard roles={protectedPages.listLogsPage.roles}>
            <NavigationBar />
            <Logs />
          </RouteGuard>
        }
      />
      <Route
        caseSensitive
        path={protectedPages.approvalClientsPage.path}
        element={
          <RouteGuard roles={protectedPages.approvalClientsPage.roles}>
            <NavigationBar />
            <ClientsApprovalPage />
          </RouteGuard>
        }
      />
      <Route
        caseSensitive
        path={protectedPages.changesClientsPage.path}
        element={
          <RouteGuard roles={protectedPages.changesClientsPage.roles}>
            <NavigationBar />
            <ClientsChangesPage />
          </RouteGuard>
        }
      />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: { instance: any }) => {
  // return (
  //   <div className="flex h-full justify-center items-center text-2xl">
  //     <h1>Portal em manutenção...</h1>
  //   </div>
  // );

  return (
    <div className="font-mulish min-h-screen">
      <MsalProvider instance={instance}>
        <PageLayout>
          <Pages />
        </PageLayout>
      </MsalProvider>
    </div>
  );
};

export default App;
