import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { LogInterface } from "../../data/logs";

export default function LogTable({ values }: { values: LogInterface[] }) {
  return (
    <Table>
      {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
      <TableHeader>
        <TableRow>
          <TableHead className="font-medium">ID</TableHead>
          <TableHead>Resource</TableHead>
          <TableHead>Efetuado por</TableHead>
          <TableHead>Efeito em</TableHead>
          <TableHead>Realizado em</TableHead>
          <TableHead>Empresa</TableHead>
          <TableHead>Metódo</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {values
          .sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          })
          .map((company: LogInterface) => (
            <TableRow key={company.id}>
              <TableCell className="font-medium">{company.id}</TableCell>
              <TableCell>{company.resourcePath}</TableCell>
              <TableCell>{company.usedBy}</TableCell>
              <TableCell>{company.effectOn}</TableCell>
              <TableCell>
                {moment(company.createdAt).format("DD/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell>{company.company}</TableCell>
              <TableCell>{company.method}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
