import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { LogIn } from "lucide-react";
import { loginRequest, makeLoginRequest } from "src/authConfig";
import { NavigationBar } from "src/components/authentication/navigation-bar";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";

import Dashboard from "./dashboard";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
  const { instance, inProgress, accounts } = useMsal();

  const activeAccount = instance.getActiveAccount();

  const handleLoginRedirect = () => {
    instance
      .loginRedirect(makeLoginRequest)
      .catch((error) => console.log(error));
  };

  return (
    <>
      <AuthenticatedTemplate>
        <NavigationBar />
        <div className="min-h-[94vh]">
          {activeAccount ? <Dashboard /> : null}
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div
          className="w-full flex min-h-[100vh]"
          style={{
            backgroundImage: "url('/bg-portal1.jpeg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "right top",
          }}
        >
          <div className="w-full grow flex flex-col items-center justify-center pb-48">
            {/* <img src="/logo-large.png" className="h-56" />
            <h1 className="text-white text-4xl mb-6">SelfService HUB</h1> */}
          </div>
          <div className="p-24 pt-80 py-48 pb-56 w-3/6 flex flex-col items-center justify-center">
            <div className="p-8 rounded-lg h-full w-full flex flex-col justify-center items-center bg-gray-200 shadow-lg ">
              {/* <Input
              value="DOMÍNIO B1CLOUD.COM.BR"
              className="text-lg text-center w-full mb-6 py-6"
              readOnly
              disabled
            /> */}
              <Button
                className="space-x-4 text-lg py-6 shadow-lg bg-[#3762a6]"
                onClick={handleLoginRedirect}
              >
                <LogIn />
                <p>Logar com Microsoft Azure</p>
              </Button>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};
