import { ArrowDown, ArrowUp } from "lucide-react";
import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { UserADInterface } from "../../data/users";
import { Button } from "../ui/button";
import ADUsersDisableDialog from "./ad-users-disable-dialog";
import ADUsersDropdownActions from "./ad-users-dropdown-actions";
import ADUsersResetPasswordDialog from "./ad-users-reset-dialog";

export function ADUsersTable({
  values,
  loadingDisable,
  loadingReset,
  handleDisableADUser,
  handleResetADUserPassword,
  hasDisable,
}: {
  values: UserADInterface[];
  hasDisable: boolean;
  handleDisableADUser: (name: string) => Promise<void>;
  handleResetADUserPassword: (
    email: string,
    name: string,
    user: string,
  ) => Promise<void>;
  loadingDisable: boolean;
  loadingReset: boolean;
}) {
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [openDisable, setOpenDisable] = useState<string | null>(null);
  const [openReset, setOpenReset] = useState<{
    email: string;
    name: string;
    user: string;
  } | null>(null);

  return (
    <>
      {openDisable && (
        <ADUsersDisableDialog
          name={openDisable}
          onClose={() => setOpenDisable(null)}
          loading={loadingDisable}
          handleDisable={async () => {
            await handleDisableADUser(openDisable);
            setOpenDisable(null);
          }}
        />
      )}
      {openReset && (
        <ADUsersResetPasswordDialog
          name={openReset?.name || ""}
          email={openReset?.email || ""}
          username={openReset?.user || ""}
          onClose={() => setOpenReset(null)}
          loading={loadingReset}
          handleReset={async () => {
            if (openReset)
              await handleResetADUserPassword(
                openReset.email,
                openReset.name,
                openReset.user,
              );
            setOpenReset(null);
          }}
        />
      )}
      <Table id="table-ads">
        {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
        <TableHeader>
          <TableRow>
            <TableHead colSpan={1} className="font-medium">
              Login
            </TableHead>
            <TableHead>Nome</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>
              <Button
                className="flex space-x-2 items-center p-2"
                onClick={() =>
                  order === "asc" ? setOrder("desc") : setOrder("asc")
                }
                variant={"ghost"}
              >
                <p>Ult. Att. Senha</p>

                {order === "asc" ? (
                  <ArrowDown size={16} />
                ) : (
                  <ArrowUp size={16} />
                )}
              </Button>
            </TableHead>
            <TableHead>Expira em:</TableHead>
            <TableHead>Status</TableHead>
            {hasDisable && <TableHead></TableHead>}
          </TableRow>
        </TableHeader>
        <TableBody>
          {values
            .sort((a, b) =>
              order === "asc"
                ? moment(a.passwordLastSet).unix() -
                  moment(b.passwordLastSet).unix()
                : moment(b.passwordLastSet).unix() -
                  moment(a.passwordLastSet).unix(),
            )
            .map((user: UserADInterface) => (
              <TableRow key={user.userPrincipalName}>
                <TableCell className="font-medium">
                  {user.userPrincipalName}
                </TableCell>
                <TableCell>{user.givenName}</TableCell>
                <TableCell>{user.mail}</TableCell>
                <TableCell>
                  {moment(user.passwordLastSet).format("DD/MM/yyyy HH:mm")}
                </TableCell>
                <TableCell>{user.expiresIn}</TableCell>
                <TableCell
                  className={
                    user.status === "Enabled"
                      ? "text-green-600"
                      : "text-red-600"
                  }
                >
                  {user.status === "Enabled" ? "Ativado" : "Desativado"}
                </TableCell>
                {hasDisable && (
                  <TableCell>
                    <ADUsersDropdownActions
                      onDisable={() => setOpenDisable(user.sAMAccountName)}
                      onReset={() =>
                        setOpenReset({
                          email: user.mail,
                          name: user.givenName,
                          user: user.sAMAccountName,
                        })
                      }
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
