import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { RefreshCw } from "lucide-react";
import { toast } from "sonner";
import { ClientsChangesTable } from "src/components/clients-changes/clients-changes-table";
import CompanyIntegrationSelect from "src/components/companys/company-integration-select";
import { Button } from "src/components/ui/button";
import { ChangesInterface } from "src/data/changes";
import { UserADInterface } from "src/data/users";
import { appRoles, protectedResources } from "../authConfig";
import { CompanyInterface } from "../data/companies";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

const ClientsChangesContent = () => {
  const { error, execute, account } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const isAdmin =
    instance
      .getActiveAccount()
      ?.idTokenClaims?.roles?.includes(appRoles.SysAdministrator) || false;

  console.log("isAdmin");
  console.log(isAdmin);

  const [company, setCompany] = useState<null | string>(null);
  const [approvals, setApprovals] = useState<null | ChangesInterface[]>(null);
  const [companies, setCompanies] = useState<null | CompanyInterface[]>(null);

  const [filtredItems, setFiltredItems] = useState<UserADInterface[]>([]);

  const [loading, setLoading] = useState(false);

  const [loadingDisable, setLoadingDisable] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);

  useEffect(() => {
    if (isAdmin && company && !approvals) {
      handleGetApprovalList();
    } else if (!isAdmin && !approvals) {
      handleGetApprovalList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, approvals, company]);

  const handleDisable = async (user: string) => {
    setLoadingDisable(true);
    await execute(
      "DELETE",
      protectedResources.users.disableAdUsersEndpoint,
      undefined,
      {
        user,
      },
    )
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetApprovalList();
        toast.success("Usuário desabilitado com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao desativar usuário");
      })
      .finally(() => {
        setLoadingDisable(false);
      });
  };

  const handleReject = async (
    reason: string,
    approvalId: string,
    gmudId: string,
  ) => {
    setLoadingReset(true);
    await execute("POST", protectedResources.approvals.gmudReproveEndpoint, {
      reason,
      id_approver: approvalId,
      id_gmud: gmudId,
    })
      .then(async (response) => {
        console.log("response");
        console.log(response);
        await handleGetApprovalList();
        toast.success("Senha resetada com sucesso");
      })
      .catch((error: any) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao resetar senha do usuário");
      })
      .finally(() => {
        setLoadingReset(false);
      });
  };

  const handleGetApprovalList = async () => {
    console.log("handleGetApprovalList");
    setLoading(true);
    execute(
      "GET",
      isAdmin
        ? protectedResources.approvals.changesListEndpoint + "/" + company
        : protectedResources.approvals.changesListEndpoint,
      undefined,
      {
        company,
      },
    )
      .then((response) => {
        setApprovals(response);
        setFiltredItems(response);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao buscar usuários");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="px-4">
      <div className="flex justify-end space-x-2">
        <div className="w-3/6 flex space-x-1">
          <CompanyIntegrationSelect
            onSelectChange={(value) => {
              setApprovals(null);
              setCompany(value);
            }}
          />
          <Button
            disabled={loading || !company}
            onClick={() => handleGetApprovalList()}
          >
            <RefreshCw className={`size-4 ${loading && "animate-spin"}`} />
          </Button>
        </div>
      </div>
      <ClientsChangesTable
        values={approvals || []}
        loadingDisable={loadingDisable}
        loadingReset={loadingReset}
      />
    </div>
  );
};

export const ClientsChangesPage = () => {
  return <ClientsChangesContent />;
};
