import { ArrowDown, ArrowUp } from "lucide-react";
import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { UserActiveInterface } from "../../data/users";
import { Button } from "../ui/button";
import ActiveUsersDisconnectDialog from "./active-users-disconnect-dialog";

export function ActiveUsersTable({
  values,
  hasKick,
  handleKickActiveUser,
  loadingDisconnect,
}: {
  values: UserActiveInterface[];
  hasKick: boolean;
  handleKickActiveUser: (
    id: string,
    host: string,
    username: string,
  ) => Promise<void>;
  loadingDisconnect: boolean;
}) {
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [openDisconnect, setOpenDisconnect] = useState<{
    name: string;
    id: string;
    host: string;
  } | null>(null);

  return (
    <>
      {openDisconnect && (
        <ActiveUsersDisconnectDialog
          name={openDisconnect.name}
          onClose={() => setOpenDisconnect(null)}
          loading={loadingDisconnect}
          handleDisconnect={async () => {
            await handleKickActiveUser(
              openDisconnect.id,
              openDisconnect.host.split("@")[1],
              openDisconnect.name,
            );
            setOpenDisconnect(null);
          }}
        />
      )}

      <Table>
        {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
        <TableHeader>
          <TableRow>
            <TableHead className="font-medium">ID</TableHead>
            <TableHead>Usuário</TableHead>
            <TableHead>Host</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>
              <Button
                className="flex space-x-2 items-center p-2 font-bold"
                onClick={() =>
                  order === "asc" ? setOrder("desc") : setOrder("asc")
                }
                variant={"ghost"}
              >
                <p>Logado em</p>

                {order === "asc" ? (
                  <ArrowDown size={16} />
                ) : (
                  <ArrowUp size={16} />
                )}
              </Button>
            </TableHead>
            <TableHead>Ações</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {values
            .sort((a, b) =>
              order === "asc"
                ? moment(a.logon_time, "DD/MM/YYYY HH:mm").unix() -
                  moment(b.logon_time, "DD/MM/YYYY HH:mm").unix()
                : moment(b.logon_time, "DD/MM/YYYY HH:mm").unix() -
                  moment(a.logon_time, "DD/MM/YYYY HH:mm").unix(),
            )
            .map((user: UserActiveInterface) => (
              <TableRow key={`${user.username}${user.host}`}>
                <TableCell className="font-medium">{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.host.split("@")[0]}</TableCell>
                <TableCell
                  className={
                    user.state === "Active" ? "text-green-600" : "text-red-600"
                  }
                >
                  {user.state === "Active" ? "Ativo" : "Desconectado"}
                </TableCell>
                <TableCell>{user.logon_time}</TableCell>
                {hasKick && (
                  <TableCell>
                    <Button
                      onClick={() =>
                        setOpenDisconnect({
                          id: user.id,
                          host: user.host,
                          name: user.username,
                        })
                      }
                    >
                      Desconectar
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
