import { useEffect, useState } from "react";
import LogTable from "../components/logs/logs-table";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import { LogInterface } from "../data/logs";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

export default function Logs() {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const [logs, setLogs] = useState<LogInterface[] | null>(null);

  const [filtredItems, setFiltredItems] = useState<LogInterface[]>([]);

  const handleGetLogs = async () => {
    const response = await execute(
      "GET",
      `${protectedResources.logs.getLogsEndpoint}`,
    );
    setLogs(response);
    setFiltredItems(response);
  };

  useEffect(() => {
    (async () => {
      if (!logs && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: protectedResources.users.scopes,
        };
        try {
          const accessToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          localStorage.setItem("backendAccessToken", accessToken.accessToken);
          await handleGetLogs();
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessToken =
                await instance.acquireTokenSilent(accessTokenRequest);
              localStorage.setItem(
                "backendAccessToken",
                accessToken.accessToken,
              );
              await handleGetLogs();
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    })();
  }, [instance, accounts, inProgress, logs]);

  return (
    <div className="p-4">{logs && <LogTable values={filtredItems} />}</div>
  );
}
